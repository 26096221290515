import React from 'react';
import { connect } from 'react-redux';
import { ReferAFriend, getCMSObject } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const ReferAFriendPage = ({ user, loading, config, cmsSourceFirebase, actions, match }) => {
    const rafTelescript = getCMSObject('data.components.teleScripts.referAFriend.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <ReferAFriend
                user={user}
                loading={loading}
                cmsSourceFirebase={cmsSourceFirebase}
                actions={actions}
                telescript={rafTelescript}
                options={{
                    variant: 'theme-primary',
                    hasCollapser: false,
                    showByDefault: true,
                    registerLink: '/register?rafcode=',
                    hash: 'refer-a-friend',
                }}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferAFriendPage);
