import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { isBrowser } from 'sg-ui-components';
import { ReadyToGo } from './RegisterForm/ReadyToGo';
import { CantFindConfirmation } from './RegisterForm/CantFindConfirmation';
import AwaitAccountConfirmation from './RegisterForm/AwaitAccountConfirmation';

//* Retrieve ID from url params
const queryString = isBrowser() ? window.location.search : '';
const urlParams = new URLSearchParams(queryString);
const queryParams = {
    passcode: urlParams.get('passcode'),
    registrarId: urlParams.get('registrar_id'),
    mode: urlParams.get('mode'),
};

const getPageMode = (mode) => {
    if (mode === 'confirm-email') return mode;
    if (mode === 'help-to-verify') return mode;

    return 'confirm-email';
};

const AccountConfirm = ({ user, actions }) => {
    const [mode, setMode] = useState(getPageMode(queryParams.mode));

    const validateUser = async (params) => {
        if (user.verifySuccess) {
            setMode('success');
        }

        if (params.passcode || params.registrarId) {
            await actions.userActions.confirm({ passcode: params.passcode, registrar_id: params.registrarId });
        }

        if (user.lastServerResponse.type === 'confirm') {
            if (user.lastServerResponse.message.code !== '_NOT_FOUND') {
                setMode('success');
            }
        }
    };

    useEffect(() => {
        validateUser(queryParams);
    }, [user.lastServerResponse.type]);

    return (
        <>
            <div className='my-4' />

            {mode === 'confirm-email' && <AwaitAccountConfirmation />}

            {mode === 'help-to-verify' && <CantFindConfirmation />}

            {mode === 'success' && <ReadyToGo />}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfirm);
