import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { Header } from './Layouts/Header';
import Footer from './Layouts/Footer';
import IdleTimeout from './IdleTimeout';
import {
    IfNotMobile,
    CMSContent,
    StyleOverrides,
    scrollToHashElement,
    ErrorBoundary,
    TeleScript,
    getCMSObject,
    getInterpolatedPageData,
    FeatureLockout,
    HeroBanner,
} from 'sg-ui-components';
import siteConfig from '../promotionConfig';
import { FooterDisclaimers } from './Layouts/Footer/FooterDisclaimers';
import PointsDashboard from './PointsDashboard';
import { camelCase } from 'lodash';
import TitleBreadcrumb from './TitleBreadcrumb';
import LoggedInOnlyPages from '../Components/LoggedInOnlyPages';
import LoadingIndicator from './LoadingIndicator';
import UserLockout from '../utils/user_lockout';

const Layout = ({ user, config, cmsSourceFirebase, actions, children, match, configReplacements, banners }) => {
    const path = match?.path ?? '';
    const pageData = getInterpolatedPageData(config?.site?.routes, match, configReplacements);
    const errorBoundaryFallback = getCMSObject('data.messages.pageErrorMessages.jsonBlock')?.[`${camelCase(path) || 'home'}Fallback`];

    //? Note: Frontend config will be requested and stored on first pageload / refresh
    useEffect(() => {
        async function configStateActions() {
            await actions.configActions.getEnvironmentConfig();
        }

        if (!config?.config?.firebase) {
            configStateActions();
            //? For debug: console.log('🟢 Initialization: Environment config set');
        }
    }, []);

    //? Note: Firebase CMS webContent and webMenu will be requested and stored on first pageload / refresh
    useEffect(() => {
        async function pageStateActions() {
            await actions.cmsSourceFirebaseActions.storeAllData({
                config: siteConfig?.testing?.firebase ?? config.config.firebase,
                docName: 'webConfig',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: siteConfig?.testing?.firebase ?? config.config.firebase,
                docName: 'webContent',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: siteConfig?.testing?.firebase ?? config.config.firebase,
                docName: 'webMenu',
            });

            await actions.cmsSourceFirebaseActions.setLoaded(true);

            const webConfigData = getCMSObject('data', 'webConfig') ?? {};
            const siteConfigWithOutFunction = JSON.parse(JSON.stringify(siteConfig));
            const siteConfigFromCMS = JSON.parse(webConfigData?.siteConfig ?? '{}') ?? {};
            const siteConfigData = { ...siteConfigWithOutFunction, ...siteConfigFromCMS };
            delete webConfigData.siteConfig;
            delete siteConfigData.testing;

            // Set config store
            await actions.configActions.setWebConfig(webConfigData);
            await actions.configActions.setSiteConfig(siteConfigData);
            await actions.configActions.setLoaded(true);

            scrollToHashElement();
        }

        if (config?.config?.firebase && !cmsSourceFirebase?.loaded) {
            pageStateActions();
            //? For debug: console.log('🟢 Initialization: CMS Data and Config Stores synced');
        }
    }, [config]);

    useEffect(() => {
        async function playerStateActions() {
            await actions.userActions.getPlayerData();
            scrollToHashElement();
        }

        playerStateActions();
        //? For debug: console.log('🟢 Initialization: Player validation completed');
    }, [user?.loggedIn]);

    // Get GTM ID based on the environment from the config api
    useEffect(() => {
        if (config?.config?.gtm_id) {
            const tagManagerArgs = {
                gtmId: config?.config?.gtm_id,
            };
            TagManager.initialize(tagManagerArgs);
        }
    }, [config?.config?.gtm_id]);

    const isLoaded = config?.loaded && cmsSourceFirebase.loaded;

    //? Redirect players to login page if the page is logged in only page and the players are not logged in
    if (pageData?.loggedInOnly && !user.loggedIn) {
        return <LoggedInOnlyPages />;
    }

    if (!isLoaded) {
        return <LoadingIndicator priority='critical' />;
    }

    return (
        <FeatureLockout config={config} page={pageData} user={user} options={{ redirectPath: pageData?.featureDisabledRedirectPath }}>
            <Helmet defaultTitle='DelawareLottery' titleTemplate='%s | DelawareLottery'>
                <title>{pageData?.title}</title>
            </Helmet>
            <IfNotMobile>
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-top' noticePlacement='web-notice-top-fixed' />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='' noticePlacement='web-notice-top' />
                <Header />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='' noticePlacement='web-notice-below-header' />
            </IfNotMobile>
            <div className={`content-root ${pageData?.className}`}>
                <ErrorBoundary fallback={<TeleScript line={errorBoundaryFallback} />} details={{ pageData, user, config }}>
                    <div className={`${pageData?.className}-container`}>
                        <div className='galaxy-fold-open-your-device d-none'></div>

                        <UserLockout currentSection={pageData?.className} disableRedirect={pageData?.className}>
                            <HeroBanner
                                banners={banners}
                                bannerName={config?.site?.banners?.homeCarousel ?? ''}
                                actions={actions}
                                allowedPaths={config?.site?.allowedBannerPaths ?? ['/', '/home']}
                                handleBannerActions={false}
                            />

                            {pageData.className !== 'account-confirm' && <PointsDashboard />}
                            <div className='layout container'>
                                <div className='main'>
                                    <div className='page-content w-100'>
                                        {path && <TitleBreadcrumb pageData={pageData} />}
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </UserLockout>

                        <IdleTimeout />
                    </div>
                </ErrorBoundary>
            </div>
            <IfNotMobile>
                <Footer />
                <FooterDisclaimers />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-bottom' noticePlacement='web-notice-bottom-fixed' />
            </IfNotMobile>
            <StyleOverrides cmsSourceFirebase={cmsSourceFirebase} />
        </FeatureLockout>
    );
};

export default Layout;
