import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { StepOne } from './step_one';
import { StepTwo } from './step_two';
import { StepThree } from './step_three';
import { StepFour } from './step_four';
import { TeleScript, getCMSObject } from 'sg-ui-components';
import './register.scss';

import promotionConfig from '../../promotionConfig';

/**********************************************************************
 * Component:  RegistrationStepsTemplate
 * Purpose:    Sets up all the main Registration steps that the user progressed
 *             through.  Contains Registration Progress Bar
 *
 * Props:       presetStep - initial step in registration process for this user
 *                           (can be passed via login in URL Parameter)
 *              user -  user data store
 *              actions - store actions (apis)
 *
 * APIs used:   None
 *
 *  Notes:
 */
const RegistrationStepsTemplate = ({ presetStep = 1, user }) => {
    const stepFromUrl = new URLSearchParams(document.location.search).get('step');
    const initialStep = stepFromUrl ? stepFromUrl : presetStep;
    const [step, setStep] = useState({ step: initialStep });

    let initialVerifyFields = {
        firstname: user.player.firstname ? user.player.firstname : '',
        lastname: user.player.lastname ? user.player.lastname : '',
        birthdate: user.player.birthdate ? user.player.birthdate : '',
        address: user.player.address ? user.player.address : '',
        phone: user.player.phone ? user.player.phone : '',
        email: user.player.phone ? user.player.email : '',
        ssn: '',
    };

    const [verifyFields, setVerifyFields] = useState(initialVerifyFields);

    //***********************************************************************************
    // Check the user store to see if any data is in there - might be true if we got here
    // via login, else - these fields are empty for a new registration.
    useEffect(() => {
        let newVerifyFields = {
            firstname: user.player.firstname ? user.player.firstname : '',
            lastname: user.player.lastname ? user.player.lastname : '',
            birthdate: user.player.birthdate ? user.player.birthdate : '',
            address: user.player.address ? user.player.address : ' ',
            phone: user.player.phone ? user.player.phone : ' ',
            email: user.player.phone ? user.player.email : '',
            ssn: '',
        };
        setVerifyFields(newVerifyFields);
    }, [user]);

    const redirectToStep2 = () => {
        if (step.step !== 1) {
            setStep({ step: 2 });
        }
    };

    return (
        <div className='my-3'>
            <RegistrationProgressBar step={step} handleLinkBack={redirectToStep2} />
            <form className='theme-form'>
                <StepOne step={step} user={user} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} />
                <StepTwo step={step} user={user} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} />
                <StepThree step={step} user={user} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} />
                {promotionConfig.needSSNToRegister ? (
                    <StepFour step={step} user={user} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} />
                ) : null}
            </form>
        </div>
    );
};

/**********************************************************************
 * Component:  RegistrationProgressBar
 * Purpose:    Keeps a record of where user is in regitstation process
 *
 * Props:      step - what step user is in the process.
 *
 * APIs used:   None
 *
 *  Notes:
 */
const RegistrationProgressBar = ({ step, handleLinkBack }) => {
    const registrationBarTelescript = getCMSObject('data.components.teleScripts.registrationBar.jsonBlock');

    return (
        <div className='registration-progress-bar'>
            <div className={`step step1 ${step.step == 1 ? 'active' : ''}`}>
                <div className='circle'>1</div>
                <span className='step-title'>
                    <TeleScript line={registrationBarTelescript?.step1Label}>Account</TeleScript>
                </span>
            </div>
            <div className={`step step2 ${step.step == 2 ? 'active' : ''}`}>
                <div className='circle' onClick={handleLinkBack}>
                    2
                </div>
                <span className='step-title'>
                    <TeleScript line={registrationBarTelescript?.step2Label}>Identification</TeleScript>
                </span>
            </div>
            <div className={`step step3 ${step.step == 3 ? 'active' : ''}`}>
                <div className='circle'>3</div>
                <span className='step-title'>
                    <TeleScript line={registrationBarTelescript?.step3Label}>Contact Info.</TeleScript>
                </span>
            </div>
            {promotionConfig.needSSNToRegister ? (
                <div className={`step step4 ${step.step == 4 ? 'active' : ''}`}>
                    <div className='circle'>4</div>
                    <span className='step-title'>
                        <TeleScript line={registrationBarTelescript?.step4Label}>Verify</TeleScript>
                    </span>
                </div>
            ) : null}
        </div>
    );
};

const RegistrationSteps = connect(mapStateToProps, mapDispatchToProps)(RegistrationStepsTemplate);

export { RegistrationSteps };
