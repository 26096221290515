import { useHistory } from 'react-router-dom';
import { CMSContent, ContentBox, ContentBoxBody, ContentBoxHead, getCMSObject, TeleScript } from 'sg-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';

/**********************************************************************
 * Component:  ReadyToGo
 * Purpose:    Simple Component that tells user they are verified
 *             and Ready To Go
 *
 * Props:      cmsSourceFirebase - reference to the Firebase CMS
 *
 * APIs used:   None
 */
const ReadyToGoTemplate = ({ cmsSourceFirebase, user, actions }) => {
    const readyToGoTeleScript = getCMSObject('data.components.teleScripts.readyToGo.contentHTML');
    const history = useHistory();

    return (
        <div className='row justify-content-center'>
            <div className='col-12 col-md-10 col-lg-8'>
                <ContentBox className='form-step' variant='theme-primary'>
                    <ContentBoxHead>Congratulations!</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='text-center'>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.sitewideSettings.mainLogo.image'
                                className='img-fluid'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </div>
                        <TeleScript line={readyToGoTeleScript}>
                            <p className='text-center'>Your email has been confirmed! You have access to all My Lottery Players Club features.</p>
                        </TeleScript>
                        <p className='text-center'>
                            <button
                                onClick={async () => (user.loggedIn ? (await actions.userActions.logout(), history.push('/login')) : history.push('/home'))}
                                type='button'
                                className='btn theme-btn theme-primary btn-lg'>
                                Continue To Login
                            </button>
                        </p>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        </div>
    );
};
const ReadyToGo = connect(mapStateToProps, mapDispatchToProps)(ReadyToGoTemplate);
export { ReadyToGo };
